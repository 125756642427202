import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { IconDeposit, IconMarket, IconTicket, IconWithdraw } from 'icons';

interface ItemProps {
  message: string;
  timestamp: string;
  action: string;
  value: string;
  currency: string;
}

const icons = {
  DEPOSIT: <IconDeposit minW={5} minH={5} />,
  WITHDRAW: <IconWithdraw minW={5} minH={5} />,
  TICKETS_USED: <IconTicket minW={5} minH={5} />,
  TICKETS_PURCHASED: <IconTicket minW={5} minH={5} />,
  MARKETPLACE_PURCHASED: (
    <IconMarket
      minW={5}
      minH={5}
      __css={{
        path: { fill: 'coralQuartz.100' },
      }}
    />
  ),
  MARKETPLACE_SELL: (
    <IconMarket
      minW={5}
      minH={5}
      __css={{
        path: { fill: 'treasuredTeal.100' },
      }}
    />
  ),
};

export const ActivityItem = ({
  action,
  currency,
  message,
  timestamp,
  value,
}: ItemProps) => {
  const date = new Date(timestamp);
  const formatter = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const formattedDate = formatter.format(date);

  return (
    <Flex
      flexDir="row"
      justify="space-between"
      px={4}
      py={2}
      align={'center'}
      bg={'whiteAlpha.200'}
    >
      <Flex align={'center'} gap={4}>
        {icons[action as keyof typeof icons]}
        <Flex flexDir={'column'}>
          <Heading
            textTransform="uppercase"
            color="whiteAlpha.800"
            fontWeight="400"
            fontSize={'14px'}
            maxW="90%"
          >
            {message}
          </Heading>
          <Text fontSize="10px" color={'whiteAlpha.600'}>
            {formattedDate}
          </Text>
        </Flex>
      </Flex>

      <Stack align="flex-end" gap={0}>
        <Text
          textTransform="uppercase"
          fontSize={'14px'}
          fontFamily={'heading'}
          color="whiteAlpha.800"
        >
          {value}
        </Text>
        <Text
          textTransform="uppercase"
          fontSize={'10px'}
          fontFamily={'heading'}
          color="whiteAlpha.600"
        >
          {currency}
        </Text>
      </Stack>
    </Flex>
  );
};
