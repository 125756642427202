/* eslint-disable react/no-unescaped-entities */
import {
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  ToastId,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { BalanceComponent } from 'components/Balance/BalanceComponent';
import { WalletModal } from './WalletModal/WalletModal';
import { RInput, RToast } from 'components';
import { useEffect, useRef, useState } from 'react';

import { WalletPopover } from './subcomponents/WalletPopover';
import { useFunds } from 'context/fundsContext';
import { IconCopyPaste, IconOctagonCheck } from 'icons';
import { useAnimatedTransition, useWallet } from 'context';
import { TransferInput } from './TransferFunds/TransferInput';
import { SenderWalletInput } from './TransferFunds/SenderWalletInput';

export const SendTab = () => {
  // Init User Wallet
  const { wallet } = useWallet();

  //  Input controllers
  const [addressToSend, setAddressToSend] = useState('');
  const [ammountToSend, setAmmountToSend] = useState('');
  const [isTransfering, setIsTransfering] = useState(false);

  const { actualTokenSelected } = useFunds();

  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);

  const { showAnimatedTransition } = useAnimatedTransition();

  const [isValueValid, setIsValueValid] = useState(false);
  const [isAddressValid, setIsAddressValid] = useState(false);

  const handleSendMatic = async () => {
    setIsTransfering(true);
    const { transferMatic } = await import(
      '../../utils/blockchain/transferNative'
    );
    if (!wallet) return;
    const result = await transferMatic(wallet, addressToSend, ammountToSend);
    console.log('result', result);
    setIsTransfering(false);
  };

  const handleSendVext = async () => {
    setIsTransfering(true);
    const { transferVext } = await import(
      '../../utils/blockchain/transferERC20'
    );
    if (!wallet) return;
    const result = await transferVext(wallet, addressToSend, ammountToSend);
    console.log('result', result);
    setIsTransfering(false);
  };

  const handleSendUsdc = async () => {
    setIsTransfering(true);
    const { transferUsdc } = await import(
      '../../utils/blockchain/transferERC20'
    );
    if (!wallet) return;
    console.log('Params', {
      aaWallet: wallet,
      to: addressToSend,
      rawAmmount: ammountToSend,
    });
    const result = await transferUsdc(wallet, addressToSend, ammountToSend);
    console.log('result', result);
    setIsTransfering(false);
  };

  const handleSend = async () => {
    try {
      switch (actualTokenSelected) {
        case 'matic':
          await handleSendMatic();
          break;
        case 'vext':
          await handleSendVext();
          break;
        case 'usdc':
          await handleSendUsdc();
          break;
        default:
          break;
      }

      // Show Animation if Success
      showAnimatedTransition({
        Icon: IconOctagonCheck,
        title: 'Transfer',
        subtitle: 'success',
        id: 'transfer-success',
        color: 'treasuredTeal.100',
        bgColor:
          'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
        gradientColor: {
          from: 'rgba(72, 223, 187, 0.2)',
          to: 'rgba(72, 223, 187, 0)',
        },
      });
    } catch (error: any) {
      // Show Error Toast if Error
      toastIdRef.current = toast({
        position: 'bottom-right',
        render: () => (
          <RToast
            variant="error"
            title={`Error sending ${actualTokenSelected}: ${error}`}
            onClose={() => toast.close(toastIdRef.current as ToastId)}
          />
        ),
      });
      setIsTransfering(false);
      console.error(error);
    }
  };

  return (
    <Flex flexDir={'column'} gap={6} pt={4} pb={4}>
      <WalletPopover />

      <BalanceComponent />

      <Stack w="full" gap={2}>
        <Heading
          fontWeight={'normal'}
          textTransform={'uppercase'}
          fontSize={'14px'}
          my={2}
        >
          Amount to Send
        </Heading>
        <TransferInput
          value={ammountToSend}
          onChange={setAmmountToSend}
          setCanSubmit={setIsValueValid}
        />
      </Stack>

      <Stack justify="space-between" w="full" gap={3}>
        <Heading
          fontWeight={'normal'}
          textTransform={'uppercase'}
          fontSize={'14px'}
          my={2}
        >
          Send to wallet address
        </Heading>

        <HStack h="54px" align={'flex-start'}>
          <SenderWalletInput
            value={addressToSend}
            onChange={setAddressToSend}
            setCanSubmit={setIsAddressValid}
          />
        </HStack>

        <Heading
          mt={6}
          fontWeight={'normal'}
          fontSize={'12px'}
          color="whiteAlpha.700"
          lineHeight={'14px'}
        >
          To send funds from your Racino wallet, simply COPY your external
          wallet address and PASTE it in then SEND for your transaction.
        </Heading>

        <Button
          variant="tertiary"
          onClick={handleSend}
          mt={4}
          isLoading={isTransfering}
          isDisabled={isTransfering || !isValueValid || !isAddressValid}
        >
          Send {actualTokenSelected}
        </Button>
      </Stack>
    </Flex>
  );
};
