import { Text, TextProps } from '@chakra-ui/react';
import { RaceStatus } from 'types';
import CountdownTimer from './CountdownTimer';

const DateWithLabel = ({
  time,
  status,
  ...rest
}: {
  time: string | number | Date;
  status: RaceStatus;
} & TextProps) => {
  const getDateText = () => {
    switch (status) {
      case 'ended':
        return (
          <>
            Ended:
            <strong>
              {new Date(time).toLocaleString([], {
                dateStyle: 'medium',
                timeStyle: 'short',
              })}
            </strong>
          </>
        );
      default:
        return <CountdownTimer time={time} status={status} />;
    }
  };

  return (
    <Text display="flex" gap="1" alignItems="center" fontSize="8px" {...rest}>
      {getDateText()}
    </Text>
  );
};

export default DateWithLabel;
