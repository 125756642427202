/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { UserAttributes } from 'types';
import { refreshToken } from 'utils/cognito/refreshToken';

export type SessionData = {
  userAttributes: UserAttributes;
  accessToken: string;
  refreshToken: string;
};

interface SessionContextProps {
  sessionData: SessionData | null;
  loginThroughExternalProvider: boolean;
  isTokensUpdated: boolean;
  saveSessionData: (sessionData: SessionData) => void;
  clearSessionData: () => void;
}

const SessionDataContext = createContext<SessionContextProps | null>(null);

export const SessionDataProvider = ({ children }: { children: ReactNode }) => {
  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const [isTokensUpdated, setIsTokensUpdated] = useState(false);
  const [loginThroughExternalProvider, setLoginThroughExternalProvider] =
    useState<boolean>(false);

  const handleRefreshToken = async () => {
    const updatedTokens = await refreshToken({
      email: sessionData?.userAttributes?.email as string,
      refreshToken: sessionData?.refreshToken as string,
    });

    if (!updatedTokens || updatedTokens?.accessToken === '') return;

    setSessionData((oldState) => {
      if (!oldState) return null;

      return {
        ...oldState,
        refreshToken: updatedTokens.refreshToken,
        accessToken: updatedTokens.accessToken,
      };
    });
    setIsTokensUpdated(true);
  };

  useEffect(() => {
    const user = localStorage.getItem('sessionData');
    if (user) {
      setSessionData(JSON.parse(user));
      if (JSON.parse(user).userAttributes.identities) {
        setLoginThroughExternalProvider(true);
      }
    }
  }, []);

  useEffect(() => {
    if (sessionData && !isTokensUpdated) handleRefreshToken();
  }, [sessionData, isTokensUpdated]);

  const saveSessionData = (sessionData: SessionData) => {
    localStorage.setItem('sessionData', JSON.stringify(sessionData));
    setSessionData(sessionData);
    setIsTokensUpdated(true);
  };

  const clearSessionData = () => {
    localStorage.removeItem('sessionData');
    localStorage.removeItem('blockPaidRaces');
    setSessionData(null);
    setIsTokensUpdated(false);
    setLoginThroughExternalProvider(false);
  };

  return (
    <SessionDataContext.Provider
      value={{
        sessionData,
        loginThroughExternalProvider,
        isTokensUpdated,
        saveSessionData,
        clearSessionData,
      }}
    >
      {children}
    </SessionDataContext.Provider>
  );
};

const useSessionData = () => {
  const context = useContext(SessionDataContext);
  if (!context) {
    throw new Error('useSessionData must be used within a SessionDataProvider');
  }
  return context;
};

export default useSessionData;
