import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProps } from 'next/app';
import { useEffect, useState } from 'react';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { RErrorBoundary } from 'components';
import {
  RootContext,
  AuthProvider,
  NotificationProvider,
  MarketPlaceProvider,
  WalletProvider,
  AnimatedTransitionProvider,
  UserProvider,
  TourProvider,
  TuningProvider,
  VideoProvider,
  LoadingTrailerProvider,
  ResultsProvider,
} from 'context';
import theme from 'utils/baseTheme';

import '@fontsource/audiowide/400.css';
import '@fontsource/chakra-petch/400.css';
import '../utils/globals.css';
import { HubProvider } from 'context/hubContext';
import { SessionDataProvider } from 'hooks/useSessionData';
import WalletLoading from 'components/Wallet/WalletLoading';
import RainbowKitWagmiConfig from 'hooks/WalletConnect/RainbowKitWagmiConfig';
import Script from 'next/script';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const [isDebug, setIsDebug] = useState(false);
  const [subdomain, setSubdomain] = useState('');

  console.log('NODE_ENV', process.env.NODE_ENV);

  useEffect(() => {
    if (Number(localStorage.getItem('debug')) === 1) {
      setIsDebug(true);
    }
    setSubdomain(window.location.hostname.split('.').slice(0, -2).join('.'));
  }, []);

  useEffect(() => {
    window.__RACINO__ = {
      logToken: () => {
        const sessionData = localStorage.getItem('sessionData');
        if (sessionData) {
          console.log(JSON.parse(sessionData).accessToken);
        }
      },
    };
  }, []);

  return (
    <SessionDataProvider>
      <QueryClientProvider client={queryClient}>
        <HubProvider>
          <MarketPlaceProvider>
            <NotificationProvider>
              <AuthProvider>
                <RainbowKitWagmiConfig>
                  <WalletProvider>
                    <ReactQueryDevtools />
                    <ChakraProvider theme={theme}>
                      <UserProvider>
                        <AnimatedTransitionProvider>
                          <TourProvider>
                            <RootContext.Provider
                              value={{ isDebug, subdomain }}
                            >
                              <LoadingTrailerProvider>
                                <ResultsProvider>
                                  <TuningProvider>
                                    <RErrorBoundary>
                                      <WalletLoading />
                                      <VideoProvider
                                        videoUrls={[
                                          '/videos/reveal-rewards-desktop.mp4',
                                          '/videos/reveal-rewards-mobile.mp4',
                                        ]}
                                      >
                                        <Script
                                          id="gtag"
                                          strategy="afterInteractive"
                                          dangerouslySetInnerHTML={{
                                            __html: `
                                              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                              })(window,document,'script','dataLayer','GTM-KVS67T6S');
                                            `,
                                          }}
                                        />
                                        <Component {...pageProps} />
                                      </VideoProvider>
                                    </RErrorBoundary>
                                  </TuningProvider>
                                </ResultsProvider>
                              </LoadingTrailerProvider>
                            </RootContext.Provider>
                          </TourProvider>
                        </AnimatedTransitionProvider>
                      </UserProvider>
                    </ChakraProvider>
                  </WalletProvider>
                </RainbowKitWagmiConfig>
              </AuthProvider>
            </NotificationProvider>
          </MarketPlaceProvider>
        </HubProvider>
      </QueryClientProvider>
    </SessionDataProvider>
  );
};

export default CustomApp;
