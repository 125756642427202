import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetRaceById = ({ raceId }: { raceId: string }) => {
  const { graphQLClient, hasToken, graphql } = useGraphQLClient();

  const racesGql = graphql(`
    query GetRaceById($raceId: String!) {
      getRaceById(id: $raceId) {
        id
        laps
        prizePool
        status
        userInRace
        setupSuggestions
        currency

        createdAt
        executedAt
        tuningPeriodEndTime
        qualifyingAnnouncementTime
        startTime
        numberOfTickets

        airTemperatureCategory
        tarmacConditionCategory
        trackRoughnessCategory
        trackTemperatureCategory
        weatherCategory
        windCategory

        playersParticipants {
          id
          position
          qualifyingGridPosition
          status
          car {
            name
            description {
              part
              description
              avgComparison
            }
            points
            earnings
            wins
            rarity
            locked

            appliedLivery {
              id
              name
              imageUrl
            }
            livery
            tyres {
              id
            }
          }
          user {
            countryFlag
            avatarId
            id
            email
            playerName
          }
          frontWingTuning
          frontWing {
            id
            usage
            type {
              name
            }
            name
            lowSettingRange
            highSettingRange
          }
          rearWingTuning
          rearWing {
            id
            usage
            type {
              name
            }
            name
            lowSettingRange
            highSettingRange
          }
          brakeCoolingTuning
          brakeCooling {
            id
            usage
            type {
              name
            }
            name
            lowSettingRange
            highSettingRange
          }
          engineCoolingTuning
          engineCooling {
            id
            usage
            type {
              name
            }
            name
            lowSettingRange
            highSettingRange
          }

          tyres {
            name
            id
          }
        }

        tier {
          name
        }

        track {
          id
          frontendName
          length
          description
          has2D
          has3D
        }
      }
    }
  `);

  const getRaces = useQuery({
    enabled: Boolean(hasToken && raceId),
    queryKey: ['raceByIdQuery', raceId],
    queryFn: async () => {
      return graphQLClient.request(racesGql, {
        raceId,
      });
    },
    cacheTime: 0, // Não cachear a query
    staleTime: 0, // Sempre considerar a query como "stale"
  });

  return getRaces;
};

export default useGetRaceById;
