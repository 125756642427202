import { Heading, Text, BoxProps, Flex } from '@chakra-ui/react';
import { CurrencyType } from 'api/generated/graphql';

import { IconTicket, IconTicketVext } from 'icons';

interface TicketProps extends BoxProps {
  numberOfTickets?: number;
  labelText?: string;
  currency?: CurrencyType;
  size?: 'sm' | 'lg' | 'xl';
}

const Ticket = ({
  numberOfTickets = 0,
  labelText = 'Admit',
  size = 'sm',
  currency = CurrencyType.Usd,
  ...rest
}: TicketProps) => {
  return (
    <Heading
      fontWeight="normal"
      as="h4"
      size="xs"
      textTransform="uppercase"
      position="relative"
      {...rest}
    >
      <Flex flexDirection="column" gap="0.5" display="inline-flex">
        {labelText && <Text color="white.80">{labelText}</Text>}
        <Flex alignItems="center" gap="0.5">
          {currency === CurrencyType.Vext ? (
            <IconTicketVext
              h={size === 'sm' ? '1.875rem' : size === 'lg' ? '2rem' : '3.5rem'}
              w="auto"
            />
          ) : (
            <IconTicket
              h={size === 'sm' ? '1.875rem' : size === 'lg' ? '2rem' : '3.5rem'}
              w="auto"
            />
          )}
          <Text
            fontFamily="body"
            color="white"
            fontSize={size === 'sm' ? 'lg' : size === 'lg' ? '3xl' : '3.125rem'}
            fontWeight="medium"
          >
            {numberOfTickets}
          </Text>
        </Flex>
      </Flex>
    </Heading>
  );
};

export default Ticket;
