import { Text, Heading, Box } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { UseMutateFunction } from '@tanstack/react-query';

import { RTyreList, RAlert } from 'components';
import { Tyres } from 'types';
import {
  SetCarPartMutation,
  MutationSetCarPartsArgs,
  Races,
} from 'api/generated/graphql';
import EngineAndTuning, { EngineAndTuningProps } from './EngineAndTuning';
import { useGetRaceById, useUserSettings } from 'hooks';

const SelectTyres = ({
  setSelectedTyres,
  selectedTyres,
  carId,
  mutateCarPart,
  ...engineAndTuningProps
}: EngineAndTuningProps & {
  setSelectedTyres: Dispatch<SetStateAction<Tyres>>;
  selectedTyres: Tyres;
  carId: string;
  mutateCarPart: UseMutateFunction<
    SetCarPartMutation,
    unknown,
    MutationSetCarPartsArgs,
    unknown
  >;
}) => {
  const { getUserSettings } = useUserSettings();

  const { data: raceByIdData } = useGetRaceById({
    raceId: engineAndTuningProps.tuningSelections.raceId,
  });
  const race = raceByIdData?.getRaceById as Races;
  const userRaceData = race?.playersParticipants?.find(
    (player) => player.user.id === getUserSettings.data?.getUser.id
  );

  return (
    <Box height="fit-content" pb="20">
      <Heading as="h1" size="lg" textTransform="uppercase" mb="1.5rem">
        Car Setup
      </Heading>

      <Heading as="h2" size="md" textTransform="uppercase" mb="3">
        Tyres
      </Heading>

      <Box mb="8">
        <Text mb="4" fontSize="0.875rem" color="white.80">
          Softer tyres have high peak grip but struggle in high temperatures and
          have a short lifespan. Treaded intermediate and wet tyres only work in
          the rain.
        </Text>

        <RAlert
          variant="info"
          description="Please note: Tyre selection won’t effect the qualifying lap."
        />
      </Box>

      <RTyreList
        raceId={engineAndTuningProps.tuningSelections.raceId}
        setSelectedTyres={setSelectedTyres}
        selectedTyres={(userRaceData?.tyres?.id || 'C2') as Tyres}
        carId={carId}
        mutateCarPart={mutateCarPart}
      />

      <EngineAndTuning
        selectedTyres={selectedTyres}
        {...engineAndTuningProps}
      />
    </Box>
  );
};

export default SelectTyres;
