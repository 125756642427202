/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Flex,
  Text,
  Heading,
  ButtonGroup,
  Box,
  Grid,
  GridItem,
  Alert,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  ReactElement,
  useState,
} from 'react';

import {
  RAlert,
  RCarCard,
  RLoadingAnimation,
  RPartCard,
  RPartInfoDrawer,
} from 'components';
import {
  IconChevron,
  IconPartBrakeCooling,
  IconPartEngineCooling,
  IconPartFrontWing,
  IconPartRearWing,
  IconPartTransmissionRatios,
} from 'icons';
import pickCarParts from 'utils/pickCarParts';
import { Cars } from 'api/generated/graphql';
import {
  AppliedLivery,
  CarPart,
  CarTier,
  Livery,
  PartGroup,
  PartNames,
  PartTypes,
  RarityTier,
} from 'types';
import { setAppliedParts } from 'utils/partData';
import { useGetCarParts } from 'hooks';
import { camelCase } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';

const SelectCar = ({
  cars,
  setSelectedCarId,
  raceAlert,
}: {
  cars: Cars[] | undefined;
  setSelectedCarId: Dispatch<SetStateAction<string | undefined>>;
  enterRaceIsSuccess?: boolean;
  raceAlert: ReactElement<typeof Alert> | undefined;
}) => {
  const queryClient = useQueryClient();
  const [selectedCarIndex, setSelectedCarIndex] = useState(0);
  const [activePartCategoryName, setActivePartCategoryName] =
    useState<PartNames>('Front Wing');
  const [selectedPart, setSelectedPart] = useState<CarPart | null>();

  const {
    isOpen: isOpenPartInfoDrawer,
    onOpen: onOpenPartInfoDrawer,
    onClose: onClosePartInfoDrawer,
  } = useDisclosure();

  const selectedCar = cars?.[selectedCarIndex];
  const { data: partsData, isLoading: isLoadingPartsData } = useGetCarParts(
    selectedCar?.id || ''
  );

  const parts = pickCarParts(selectedCar as Cars);
  const appliedPartIds = parts.map((p) => p.id);
  const carouselData: PartGroup[] =
    partsData?.getCarParts.map((partGroup) => {
      return {
        id: partGroup.id,
        partGroupName: partGroup.name,
        partsInGroup: partGroup.carParts.map((part) => {
          return {
            partType: camelCase(partGroup.name) as PartTypes,
            ...part,
          };
        }),
      };
    }) || [];

  useEffect(() => {
    if (selectedCar) {
      setSelectedCarId(selectedCar.id);
    }
  }, [selectedCar, setSelectedCarId]);

  const handleChangeCarPrev = () => {
    if (selectedCarIndex > 0) {
      setSelectedCarIndex(selectedCarIndex - 1);
    }
  };

  const handleChangeCarNext = () => {
    if (selectedCarIndex < (cars?.length || 0) - 1) {
      setSelectedCarIndex(selectedCarIndex + 1);
    }
  };

  const appliedParts = setAppliedParts({ carouselData, appliedPartIds });

  const activePartGroup = appliedParts.find(
    (group) => group.partGroupName === activePartCategoryName
  );

  const rankedParts = activePartGroup?.partsInGroup
    .sort((a, b) => {
      if (a?.rankRequired && b?.rankRequired) {
        return a.rankRequired - b.rankRequired;
      }

      return 0;
    })
    .filter((part) => !part.locked);

  useEffect(() => {
    if (!isOpenPartInfoDrawer)
      queryClient.invalidateQueries({
        queryKey: ['partsQuery'],
      });
  }, [isOpenPartInfoDrawer]);

  useEffect(() => {
    if (!isLoadingPartsData && !partsData)
      queryClient.invalidateQueries({
        queryKey: ['partsQuery'],
      });
  }, [partsData, isLoadingPartsData]);

  return (
    <>
      <Box height="fit-content" pb="20">
        <Flex w="full" mb="3">
          <Tooltip label="Select the Car & Parts you want to use" openDelay={500}>
            <Heading as="h1" size="lg" textTransform="uppercase" mr="6">
              Select Car
            </Heading>
          </Tooltip>

          <ButtonGroup ml="auto" gap={3}>
            <Button
              variant="secondary-outlined"
              width="12"
              isDisabled={selectedCarIndex === 0}
              onClick={handleChangeCarPrev}
              id="previous-car-button"
            >
              <IconChevron transform="rotate(180deg)" />
            </Button>

            <Button
              variant="secondary-outlined"
              width="12"
              isDisabled={selectedCarIndex === (cars?.length || 0) - 1}
              onClick={handleChangeCarNext}
              id="next-car-button"
            >
              <IconChevron />
            </Button>
          </ButtonGroup>
        </Flex>

        <Text mb="6">Select the right car for the right conditions.</Text>

        {selectedCar && (
          <RCarCard
            showRepairButton
            carParts={parts}
            carId={selectedCar?.id}
            hasInsetBorder={false}
            alert={raceAlert}
            locked={selectedCar?.locked}
            vehicleName={selectedCar?.name || ''}
            livery={(selectedCar?.livery || '') as Livery}
            appliedLivery={(selectedCar?.appliedLivery || '') as AppliedLivery}
            backgroundPreload
            isTrialCar={selectedCar?.tier?.rank === 0}
            tier={selectedCar?.tier?.name as CarTier}
            rarity={selectedCar?.rarity as RarityTier}
            timesUsed={selectedCar?.timesUsed}
            maximumTimesUsed={selectedCar?.maximumTimesUsed}
            nextRepairAt={selectedCar?.nextRepairAt}
            usageSinceLastRepair={selectedCar?.usageSinceLastRepair}
            mb="6"
            imageProps={{
              paddingY: 8,
              transform: 'scale(0.85)'
            }}
            showCarHealth
          />
        )}

        <Heading as="h2" size="md" textTransform="uppercase" mb="6">
          Parts Setup
        </Heading>

        <Grid templateColumns="repeat(5, 1fr)" gap={3} w="full" mb="1rem">
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Front Wing'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Front Wing')}
              id="front-wing-button"
            >
              <IconPartFrontWing h="1rem" w="auto" />
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Rear Wing'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Rear Wing')}
              id="rear-wing-button"
            >
              <IconPartRearWing h="1rem" w="auto" />
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Engine Cooling'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Engine Cooling')}
              id="engine-cooling-button"
            >
              <IconPartEngineCooling h="1rem" w="auto" />
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Brake Cooling'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Brake Cooling')}
              id="brake-cooling-button"
            >
              <IconPartBrakeCooling h="1rem" w="auto" />
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant={
                activePartCategoryName === 'Transmission Ratios'
                  ? 'primary-ghost'
                  : 'secondary-outlined'
              }
              w="full"
              py="0.5rem"
              onClick={() => setActivePartCategoryName('Transmission Ratios')}
              id="transmission-ratios-button"
            >
              <IconPartTransmissionRatios h="1rem" w="auto" />
            </Button>
          </GridItem>
        </Grid>

        <Heading as="h2" size="0.875rem" textTransform="uppercase" mb="1rem">
          {activePartCategoryName}
        </Heading>

        {!isLoadingPartsData && partsData ? (
          <Grid templateColumns="repeat(1, 1fr)" gap={3}>
            {rankedParts?.length === 0 ? (
              <RAlert
                variant="error"
                description="This car doesn't have parts in this category."
              />
            ) : (
              rankedParts?.map((part) => {
                if (part.locked) return null;

                return (
                  <GridItem key={part.id}>
                    <RPartCard
                      id={`${part.id}-part-card`}
                      carPart={part}
                      cursor="pointer"
                      showPartRaceInfo
                      onClick={() => {
                        onOpenPartInfoDrawer();
                        setSelectedPart(part);
                      }}
                    />
                  </GridItem>
                );
              })
            )}
          </Grid>
        ) : (
          <Flex w="full" alignItems="center" justifyContent="center">
            <RLoadingAnimation />
          </Flex>
        )}
      </Box>

      <RPartInfoDrawer
        isOpen={isOpenPartInfoDrawer}
        onClose={onClosePartInfoDrawer}
        isPartApplied={appliedPartIds.includes(selectedPart?.id || '')}
        carPart={selectedPart as CarPart}
        carId={selectedCar?.id || ''}
      />
    </>
  );
};

export default SelectCar;
