/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Heading, Text, Box, Tooltip } from '@chakra-ui/react';
import { useEffect, Dispatch, SetStateAction } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  RaceTuningInput,
  RaceParticipants,
  Races,
  CarParts,
} from 'api/generated/graphql';
import { RAlert } from 'components';
import { PartTypes, SetupSuggestions, Tyres } from 'types';
import { useTuning } from 'context';
import { useGetRaceById, useTuningForRace, useUserSettings } from 'hooks';
import { PartSlider } from 'components/PartInfo/PartSlider';

export interface EngineAndTuningProps {
  tuningSelections: RaceTuningInput;
  setTuningSelections: Dispatch<SetStateAction<RaceTuningInput>>;
  parts: RaceParticipants;
  setupSuggestions: SetupSuggestions;
  selectedTyres: Tyres;
  tuningError: unknown;
}

const getPartName = (name: string) => {
  return (
    name?.charAt(0)?.toLowerCase() +
    name?.replace(' ', '').slice(1) +
    'Tuning' || ''
  );
};

const EngineAndTuning = ({
  tuningSelections,
  setTuningSelections,
  parts,
  setupSuggestions,
  selectedTyres,
  tuningError,
}: EngineAndTuningProps) => {
  const {
    setBrakeCoolingTuning,
    setEngineCoolingTuning,
    setFrontWingTuning,
    setRearWingTuning,
    setTransmissionTuning,
    setIsLoadingTuning,
    setEngineModeTuning,
    setSuspensionRideHeightTuning,
    setSuspensionStiffnessTuning,
  } = useTuning();

  const { getUserSettings } = useUserSettings();

  const { data: raceByIdData } = useGetRaceById({
    raceId: tuningSelections.raceId,
  });
  const race = raceByIdData?.getRaceById as Races;
  const userRaceData = race?.playersParticipants?.find(
    (player) => player.user.id === getUserSettings.data?.getUser.id
  );

  useEffect(() => {
    setBrakeCoolingTuning(userRaceData?.brakeCoolingTuning || 50);
    setEngineCoolingTuning(userRaceData?.engineCoolingTuning || 50);
    setFrontWingTuning(userRaceData?.frontWingTuning || 50);
    setRearWingTuning(userRaceData?.rearWingTuning || 50);
    setTransmissionTuning(userRaceData?.transmissionTuning || 50);
  }, [userRaceData]);

  const { tuningForRace } = useTuningForRace();
  const { mutateAsync: mutateTuning } = tuningForRace;

  const partsForTuning = [
    userRaceData?.frontWing,
    userRaceData?.rearWing,
    userRaceData?.brakeCooling,
    userRaceData?.engineCooling,
  ].filter((part) => part);

  const partInfos: any = {
    'Front Wing': { title1: 'HANDLING' },
    'Rear Wing': { title1: 'GRIP', title2: 'SPEED' },
    'Engine Cooling': { title1: 'COOLING', title2: 'GRIP' },
    'Brake Cooling': { title1: 'BRAKING', title2: 'GRIP' },
    Transmission: { title1: 'SPEED', title2: 'ACCURACY' },
  };

  const defaultValues: Partial<Record<PartTypes, number>> = {};
  partsForTuning.forEach((part) => {
    defaultValues[part?.type.name as PartTypes] = Number(
      tuningSelections[`${part?.type.name}Tuning` as keyof RaceTuningInput]
    );
  });

  const queryClient = useQueryClient();

  const handleSliderOnChangeEnd = async (tunedPart: {
    name: string;
    val: number;
  }) => {
    setIsLoadingTuning(true);
    setTuningSelections((selections) => {
      return {
        ...selections,
        [tunedPart.name]: tunedPart.val,
      };
    });

    await mutateTuning({
      raceTuningInput: {
        ...tuningSelections,
        [tunedPart.name]: tunedPart.val,
      },
    });

    queryClient.invalidateQueries({
      queryKey: ['raceByIdQuery', tuningSelections.raceId],
    });
    setIsLoadingTuning(false);

    switch (tunedPart.name) {
      case 'brakeCoolingTuning':
        setBrakeCoolingTuning(tunedPart.val);
        break;
      case 'engineCoolingTuning':
        setEngineCoolingTuning(tunedPart.val);
        break;
      case 'frontWingTuning':
        setFrontWingTuning(tunedPart.val);
        break;
      case 'rearWingTuning':
        setRearWingTuning(tunedPart.val);
        break;
      case 'transmissionTuning':
        setTransmissionTuning(tunedPart.val);
        break;
      case 'engineModeTuning':
        setEngineModeTuning(tunedPart.val);
        break;
      case 'suspensionRideHeightTuning':
        setSuspensionRideHeightTuning(tunedPart.val);
        break;
      case 'suspensionStiffnessTuning':
        setSuspensionStiffnessTuning(tunedPart.val);
        break;
      default:
        break;
    }
  };

  const tuningRecommendation = setupSuggestions?.[selectedTyres] || {};
  const tuningEdgeThreshold = 2;

  const getMinSuggestion = (part: CarParts): number => {
    const tuningKey = `${getPartName(part?.type.name || '')}MinimumGreen`;
    const suggestionValue =
      (part?.type.name &&
        tuningRecommendation[tuningKey as keyof typeof tuningRecommendation]) ||
      0;
    let suggestion = suggestionValue;
    if (suggestionValue <= (part.lowSettingRange as number)) {
      suggestion = part.lowSettingRange as number;
    }
    if (
      suggestionValue >=
      (part.highSettingRange as number) - tuningEdgeThreshold
    ) {
      suggestion = (part.highSettingRange as number) - tuningEdgeThreshold;
    }
    return suggestion;
  };

  const getMaxSuggestion = (part: CarParts): number => {
    const tuningKey = `${getPartName(part?.type.name || '')}MaximumGreen`;
    const suggestionValue =
      (part?.type.name &&
        tuningRecommendation[tuningKey as keyof typeof tuningRecommendation]) ||
      0;
    let suggestion = suggestionValue;
    if (
      suggestionValue <=
      (part.lowSettingRange as number) + tuningEdgeThreshold
    ) {
      suggestion = (part.lowSettingRange as number) + tuningEdgeThreshold;
    }
    if (suggestionValue >= (part.highSettingRange as number)) {
      suggestion = part.highSettingRange as number;
    }
    return suggestion;
  };

  return (
    <Box height="fit-content">
      <Tooltip label="Fine tune your setup for the conditions, range is limited to the parts chosen." openDelay={500}>
        <Heading as="h1" size="md" textTransform="uppercase" mb="3">
          Engine & Tuning
        </Heading>
      </Tooltip>

      <Text fontSize="0.875rem" color="white.80">
        Tuning your car for weather and track conditions is crucial for racing
        success. Don&apos;t forget to consider wind direction and track
        elevation. Finding the right balance is key.
      </Text>

      {Boolean(tuningError) && (
        <RAlert
          variant="error"
          description="There was an error saving tuning values."
          mt="8"
        />
      )}

      <Flex flexDirection="column" gap="1.5rem" mt="1rem" color="white.80">
        {partsForTuning &&
          partsForTuning.map((part, index) => {
            return (
              <PartSlider
                key={index}
                part={part}
                partInfos={partInfos}
                userRaceData={userRaceData}
                getMaxSuggestion={getMaxSuggestion}
                getMinSuggestion={getMinSuggestion}
                getPartName={getPartName}
                handleSliderOnChangeEnd={handleSliderOnChangeEnd}
              />
            );
          })}
      </Flex>
    </Box>
  );
};

export default EngineAndTuning;
