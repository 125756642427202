import isNumber from 'lodash.isnumber';

import { IconCheckeredFlag, IconDollar, IconRace } from 'icons';
import { RPositionAndPoints, RTiers } from 'components';
import { colors } from 'utils/themeConstants';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { CarTier, RarityTier } from 'types';

const RCarDetails = ({
  tier,
  wins = 0,
  earnings = 0,
  points = 0,
  rarity = 'common',
}: {
  tier: CarTier;
  wins?: number | undefined;
  earnings?: number | undefined;
  points?: number | undefined;
  rarity?: RarityTier | undefined;
}) => {
  const columns = [
    {
      heading: 'RARITY',
      icon: (
        <RTiers
          tierType="rarity"
          tier={rarity}
          showTitle
          size={{ sm: 'sm', lg: 'md' }}
          lineHeight="1"
        />
      ),
      color: colors.white[80],
      tooltip: 'Car\'s Current Rarity',
    },
    {
      heading: 'TIER',
      icon: (
        <RTiers
          tierType="driver"
          tier={tier}
          showTitle
          size={{ sm: 'sm', lg: 'md' }}
          lineHeight="1"
        />
      ),
      color: colors.white[80],
      tooltip: 'Car\'s Current Tier',
    },
    {
      heading: 'POINTS',
      icon: <RPositionAndPoints carPosition={1} points={points} />,
      color: colors.darkVoid[40],
      tooltip: 'Car\'s Total Points',
    },
    {
      // TODO replace with Currency component
      heading: 'EARNINGS',
      value: earnings,
      icon: (
        <IconDollar
          w={{ base: '0.625rem' }}
          h="auto"
          __css={{
            path: {
              fill: 'treasuredTeal.100',
            },
          }}
        />
      ),
      color: colors.aphroditeAqua[100],
      tooltip: 'Car\'s Total USDC Earnings',
    },
  ];

  return (
    <Flex
      gap={{ base: '2.5vw' }}
      justify="space-between"
      align="center"
      alignItems="top"
    >
      {columns
        .filter(item => !(item.heading === 'RARITY' && rarity === 'trial')) // Filter out RARITY if it matches hiddenRarity
        .map((item) => {
          return (
            <Tooltip label={item.tooltip} openDelay={500}>
              <Flex
                key={item.heading}
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                whiteSpace="nowrap"
                gap="1"
              >
                <Text
                  color={colors.white[60]}
                  fontSize="sm"
                  textAlign="right"
                  textTransform="uppercase"
                >
                  {item.heading}
                </Text>

                <Flex
                  justify="end"
                  align="center"
                  gap="1"
                  flexDirection={{ base: 'column', md: 'row' }}
                >
                  {item.icon}

                  {isNumber(item.value) && (
                    <Box
                      color={item.color}
                      fontSize={{ base: 'md', lg: 'lg' }}
                      lineHeight="1"
                    >
                      {item.value}
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Tooltip>
          );
        })}
    </Flex>
  );
};

export default RCarDetails;
