import { Flex, Heading, BoxProps, Grid, GridItem, Tooltip } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseMutateFunction, useQueryClient } from '@tanstack/react-query';

import {
  SetCarPartMutation,
  MutationSetCarPartsArgs,
} from 'api/generated/graphql';
import { RBaseCard, RBaseCardGradient, RLoadingAnimation } from 'components';
import {
  IconTyreC1,
  IconTyreC2,
  IconTyreC3,
  IconTyreC4,
  IconTyreC5,
  IconTyreInter,
  IconTyreWet,
  IconCornerCheckmarkLight,
} from 'icons';
import { SVGIcon, Tyres } from 'types';
import { useGetCarParts, useTuningForRace } from 'hooks';
import PartInfo from 'components/PartInfo/PartInfo';
import { useTuning } from 'context';

export const TyreCard = ({
  heading,
  subheading,
  icon: Icon,
  isSelected,
  ...rest
}: BoxProps & {
  heading: string;
  subheading?: string;
  icon: SVGIcon;
  isSelected: boolean;
}) => {
  type TyreStatusKey = 'C1' | 'C2' | 'C3' | 'C4' | 'C5' | 'WET' | 'INTER';
  type TyreStatusValue =
    | 'negative-2'
    | 'negative-1'
    | 'neutral'
    | 'positive-1'
    | 'positive-2';

  const tyreStatus: Record<TyreStatusKey, TyreStatusValue> = {
    C1: 'negative-2',
    C2: 'negative-1',
    C3: 'neutral',
    C4: 'positive-1',
    C5: 'positive-2',
    WET: 'positive-2',
    INTER: 'positive-1',
  };

  const isValidHeading = (key: string): key is TyreStatusKey => {
    return key in tyreStatus;
  };

  const tyreDescription = {
    C1: 'Highest temperature range and durability, lowest grip, struggles first with moisture.',
    C2: 'High temperature range and durability, relatively low grip.',
    C3: 'Wide temperature range, struggles at extremes; mid-level grip and durability; default choice.',
    C4: 'Low temperature range, low durability, high grip.',
    C5: 'Lowest temperature range, least durable, highest grip; usable in light rain.',
    INTER: 'Optimal for light to moderate rain, suitable when the track is wet without significant puddles.',
    WET: 'Best for heavy rain with standing and running water on the track.'
  };

  return (
    <Tooltip label={tyreDescription[heading as keyof typeof tyreDescription]} openDelay={500}>
      <GridItem>
        <RBaseCard h="6rem" {...rest}>
          {isSelected && (
            <IconCornerCheckmarkLight position="absolute" top="0" right="0" />
          )}

          <Flex flexDirection="row" width="full" padding="4" pointerEvents="none">
            <PartInfo
              title1={heading === 'WET' || heading === 'INTER' ? 'RAIN' : 'GRIP'}
              status1={isValidHeading(heading) ? tyreStatus[heading] : 'neutral'}
              px="0"
            />
            <Flex h="4rem" flexDir="column" justifyContent="flex-end">
              <Heading
                as="h2"
                fontSize={{ base: '0.75rem', md: '1rem' }}
                fontWeight={400}
                maxW="100px"
                color={isSelected ? 'white.95' : 'white.60'}
              >
                {subheading}
              </Heading>
            </Flex>
            <Icon ml="auto" w="4rem" h="4rem" />
          </Flex>

          <RBaseCardGradient
            gradientColor={isSelected ? 'green' : 'grey'}
            variant="gradientToTransparent"
            hasInsetBorder
          />
        </RBaseCard>
      </GridItem>
    </Tooltip>
  );
};

const TyreList = ({
  setSelectedTyres,
  selectedTyres,
  carId,
  mutateCarPart,
  raceId,
}: {
  setSelectedTyres: Dispatch<SetStateAction<Tyres>>;
  selectedTyres: Tyres;
  carId: string;
  mutateCarPart: UseMutateFunction<
    SetCarPartMutation,
    unknown,
    MutationSetCarPartsArgs,
    unknown
  >;
  raceId: string;
}) => {
  const { data: carPartsData, isLoading: isLoadingCarParts } =
    useGetCarParts(carId);

  const { isLoadingTuning, setIsLoadingTuning } = useTuning();
  const { tuningForRace } = useTuningForRace();
  const { mutateAsync: mutateTuning } = tuningForRace;

  const [opmitmisticSelectedTyres, setOpmitmisticSelectedTyres] = useState<Tyres>(selectedTyres);

  const tyres =
    carPartsData &&
    carPartsData.getCarParts.find((part) => part.name === 'Tyres');

  const tyreIconLookup = {
    C1: IconTyreC1,
    C2: IconTyreC2,
    C3: IconTyreC3,
    C4: IconTyreC4,
    C5: IconTyreC5,
    INTER: IconTyreInter,
    WET: IconTyreWet,
  };



  const getTyreIcon = (TyreId: Tyres) => {
    return tyreIconLookup[TyreId] || <></>;
  };

  const queryClient = useQueryClient();

  const handleChangeTyre = async (tyreId: Tyres) => {
    if (isLoadingTuning) return;
    setIsLoadingTuning(true);
    setOpmitmisticSelectedTyres(tyreId);
    await mutateTuning({
      raceTuningInput: {
        raceId,
        tyresId: tyreId,
      },
    });
    queryClient.invalidateQueries({
      queryKey: ['raceByIdQuery', raceId],
    });
    setIsLoadingTuning(false);
  };

  useEffect(() => {
    setOpmitmisticSelectedTyres(selectedTyres);
  }, [selectedTyres])

  return (
    <>
      {isLoadingCarParts && (
        <Flex
          h="full"
          w="full"
          shrink={0}
          alignItems="center"
          justifyContent="center"
        >
          <RLoadingAnimation />
        </Flex>
      )}
      <Grid w="full" gridTemplateColumns="1fr 1fr" gap="0.625rem" mb="1.5rem">
        {!isLoadingCarParts &&
          tyres?.carParts
            .filter((tyre) => !tyre.locked)
            .sort((a, b) => a.id.localeCompare(b.id)) //sorting alphabetically for now
            .map((tyre) => {
              return (
                <TyreCard
                  //todo: remove array filter and add locked prop when jeremy addes designs for locked state. temporarily filtering out locked parts.
                  key={tyre.id}
                  heading={tyre.id}
                  subheading={tyre.name}
                  icon={getTyreIcon(tyre.id as Tyres)}
                  isSelected={opmitmisticSelectedTyres === tyre.id}
                  onClick={() => handleChangeTyre(tyre.id as Tyres)}
                  opacity={isLoadingTuning ? 0.8 : 1}
                  cursor={isLoadingTuning ? 'not-allowed' : 'pointer'}
                />
              );
            })}
      </Grid>
    </>
  );
};

export default TyreList;
