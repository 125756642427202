import { useQuery } from '@tanstack/react-query';

import { useGraphQLClient } from 'hooks';

const useGetVextMessageForCars = ({
  vextRate,
  deadline,
}: {
  vextRate: string;
  deadline: string;
}) => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();

  const getVextMessageFn = graphql(`
    query getVextMessageForCars($vextMessageReq: VextMessageReq!) {
      getVextMessageForCars(vextMessageReq: $vextMessageReq) {
        tokenPriceVEXT
        r
        s
        v
      }
    }
  `);

  const getVextMessageForCars = useQuery({
    enabled: Boolean(hasToken) && !!vextRate,
    queryKey: ['getVextMessageQuery'],
    queryFn: async () => {
      return await graphQLClient.request(getVextMessageFn, {
        vextMessageReq: { vextRate, deadline: Number(deadline) },
      });
    },
  });

  return getVextMessageForCars;
};

export default useGetVextMessageForCars;
