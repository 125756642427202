import {
  AirTemperatureCategory,
  TrackTemperatureCategory,
} from 'api/generated/graphql';

type ColorDetails = {
  defaultColor: string;
  fromColor: string;
  toColor: string;
};

type AirColorsType = {
  [key in AirTemperatureCategory]: ColorDetails;
};
type TrackColorsType = {
  [key in TrackTemperatureCategory]: ColorDetails;
};

export const AirTemperatureColors: AirColorsType = {
  [AirTemperatureCategory.Cold]: {
    defaultColor: 'rgba(121, 226, 242, 1)',
    fromColor: 'rgba(121, 226, 242, 0.1)',
    toColor: 'rgba(121, 226, 242, 0.7)',
  },
  [AirTemperatureCategory.Moderate]: {
    defaultColor: 'rgba(121, 242, 192, 1)',
    fromColor: 'rgba(121, 242, 192, 0.1)',
    toColor: 'rgba(121, 242, 192, 0.7)',
  },
  [AirTemperatureCategory.Warm]: {
    defaultColor: 'rgba(255, 227, 128, 1)',
    fromColor: 'rgba(255, 227, 128, 0.1)',
    toColor: 'rgba(255, 227, 128, 0.7)',
  },
  [AirTemperatureCategory.Hot]: {
    defaultColor: 'rgba(255, 143, 115, 1)',
    fromColor: 'rgba(255, 143, 115, 0.1)',
    toColor: 'rgba(255, 143, 115, 0.7)',
  },
  [AirTemperatureCategory.ExtremelyHot]: {
    defaultColor: 'rgba(216, 56, 50, 1)',
    fromColor: 'rgba(216, 56, 50, 0.1)',
    toColor: 'rgba(216, 56, 50, 0.7)',
  },
};

export const TrackTemperatureColors: TrackColorsType = {
  [TrackTemperatureCategory.ExtremelyCold]: {
    defaultColor: 'rgba(121, 226, 242, 1)',
    fromColor: 'rgba(121, 226, 242, 0.1)',
    toColor: 'rgba(121, 226, 242, 0.7)',
  },
  [TrackTemperatureCategory.Cold]: {
    defaultColor: 'rgba(121, 226, 242, 1)',
    fromColor: 'rgba(121, 226, 242, 0.1)',
    toColor: 'rgba(121, 226, 242, 0.7)',
  },
  [TrackTemperatureCategory.Moderate]: {
    defaultColor: 'rgba(121, 242, 192, 1)',
    fromColor: 'rgba(121, 242, 192, 0.1)',
    toColor: 'rgba(121, 242, 192, 0.7)',
  },
  [TrackTemperatureCategory.Warm]: {
    defaultColor: 'rgba(255, 227, 128, 1)',
    fromColor: 'rgba(255, 227, 128, 0.1)',
    toColor: 'rgba(255, 227, 128, 0.7)',
  },
  [TrackTemperatureCategory.Hot]: {
    defaultColor: 'rgba(255, 143, 115, 1)',
    fromColor: 'rgba(255, 143, 115, 0.1)',
    toColor: 'rgba(255, 143, 115, 0.7)',
  },
  [TrackTemperatureCategory.ExtremelyHot]: {
    defaultColor: 'rgba(216, 56, 50, 1)',
    fromColor: 'rgba(216, 56, 50, 0.1)',
    toColor: 'rgba(216, 56, 50, 0.7)',
  },
};
