import {
  Button,
  Flex,
  UseDisclosureProps,
  ButtonGroup,
  Box,
  Text,
  Show,
} from '@chakra-ui/react';
import { useState } from 'react';

import { Races, RaceParticipants } from 'api/generated/graphql';
import {
  RDrawer,
  RRaceStatus,
  RRaceInfo,
  RTiers,
  RDateWithLabel,
  RRaceGrid,
  RPlacingPosition,
} from 'components';
import { RaceStatus, RaceTier } from 'types';
import {
  IconAustinMinimapMarkers,
  IconBarcelonaMinimapMarkers,
  IconBudapestMinimapMarkers,
  IconImolaMinimapMarkers,
  IconLusailMinimapMarkers,
  IconMelbourneMinimapMarkers,
  IconMontrealMinimapMarkers,
  IconSakhirMinimapMarkers,
  IconSilverstoneMinimapMarkers,
  IconSpaMinimapMarkers,
  IconSpielbergMinimapMarkers,
  IconSuzukaMinimapMarkers,
  IconZandvoortMinimapMarkers,
  IconDetails,
  IconResults,
  IconPerformance,
  IconEyeHidden,
  IconEyeShow,
} from 'icons';
import { useGetUnityUrl, useSessionData, useUserSettings } from 'hooks';

import Performance from './Performance';
import { isMobileDevice } from 'utils/helpers';
import PlacingResults from 'components/PlacingResults/PlacingResults';
import { useResults } from 'context';

type RaceDrawerProps = {
  race: Races;
};

type RaceMinimapMarkersType = {
  [key: string]: React.ElementType;
};

const RaceMinimapMarkers: RaceMinimapMarkersType = {
  Belgium: IconSpaMinimapMarkers,
  Spain: IconBarcelonaMinimapMarkers,
  'United Kingdom': IconSilverstoneMinimapMarkers,
  Netherlands: IconZandvoortMinimapMarkers,
  USA: IconAustinMinimapMarkers,
  Italy: IconImolaMinimapMarkers,
  Bahrain: IconSakhirMinimapMarkers,
  Qatar: IconLusailMinimapMarkers,
  Hungary: IconBudapestMinimapMarkers,
  Austria: IconSpielbergMinimapMarkers,
  Canada: IconMontrealMinimapMarkers,
  Japan: IconSuzukaMinimapMarkers,
  Australia: IconMelbourneMinimapMarkers,
};

const RaceResultsDrawer = ({
  race,
  isOpen = false,
  onClose = () => null,
}: UseDisclosureProps & RaceDrawerProps) => {
  const { getUserSettings } = useUserSettings();
  const { url } = useGetUnityUrl({
    raceId: race.id,
    userId: getUserSettings.data?.getUser.id,
  });
  const trackName = race?.track?.frontendName || '';
  const { showAllResults, setShowAllResults } = useResults();
  const TrackMinimapMarkersIcon =
    trackName && trackName in RaceMinimapMarkers
      ? RaceMinimapMarkers[trackName]
      : null;

  const { sessionData } = useSessionData();
  const [currentTab, setCurrentTab] = useState<
    'details' | 'performance' | 'results'
  >('details');

  const currentPlayer = (race?.playersParticipants as RaceParticipants[]).find(
    (player) => {
      if (!player.user) {
        return null;
      }

      return player.user.email === sessionData?.userAttributes?.email;
    }
  );

  const winnerPlayer = (race?.playersParticipants as RaceParticipants[]).find(
    (player) => {
      if (!player.user) {
        return null;
      }

      return player.position === 1;
    }
  );

  return (
    <RDrawer
      heading={
        <>
          <RTiers tier={race?.tier?.name as RaceTier} tierType="car" mr="2" />
          {race?.track?.frontendName}
        </>
      }
      headerContent={
        <RRaceInfo race={race} hasDetails />
      }
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      drawerBody={
        <Flex flexDirection="column" py="4" width="full">
          <Box pb="20" height="calc(100% - 2rem)">
            <ButtonGroup mb="6" gap="3" w="full">
              <Button
                w="full"
                variant={
                  currentTab === 'details'
                    ? 'primary-ghost'
                    : 'secondary-outlined'
                }
                onClick={() => setCurrentTab('details')}
              >
                <Show above="md">Details</Show>
                <Show below="md">
                  <IconDetails />
                </Show>
              </Button>

              <Button
                w="full"
                variant={
                  currentTab === 'performance'
                    ? 'primary-ghost'
                    : 'secondary-outlined'
                }
                onClick={() => setCurrentTab('performance')}
              >
                <Show above="md">Performance</Show>
                <Show below="md">
                  <IconPerformance />
                </Show>
              </Button>

              <Button
                w="full"
                variant={
                  currentTab === 'results'
                    ? 'primary-ghost'
                    : 'secondary-outlined'
                }
                onClick={() => setCurrentTab('results')}
              >
                <Show above="md">Results</Show>
                <Show below="md">
                  <IconResults />
                </Show>
              </Button>
            </ButtonGroup>

            {currentTab === 'details' && (
              <>
                <Flex alignItems="center" w="full" justifyContent="center">
                  {trackName && TrackMinimapMarkersIcon && (
                    <TrackMinimapMarkersIcon width={{ base: '50%', md: '60%' }} height="auto" /> 
                  )}
                </Flex>

                {currentPlayer?.user?.playerName && currentPlayer?.position && (
                  <PlacingResults 
                    playerName={currentPlayer?.user?.playerName} 
                    alreadyOpen={showAllResults} 
                    playerPosition={currentPlayer?.position}
                  />
                )}
              </>
            )}

            {currentTab === 'performance' && (
              <Performance
                race={race as Races}
                currentPlayerId={currentPlayer?.id || ''}
              />
            )}

            {currentTab === 'results' && (
              <>
                 <Button
                    onClick={() => setShowAllResults(!showAllResults)}
                    variant="secondary-outlined"
                    w="full"
                    mb="6"
                    ml="auto"
                  >
                  SHOW RESULTS
                  {showAllResults ? (
                    <IconEyeHidden ml="0.5rem" />
                  ) : (
                    <IconEyeShow ml="0.5rem" />
                  )}
                </Button>
                <RRaceGrid
                  playersParticipants={
                    race.playersParticipants as RaceParticipants[]
                  }
                  text="Here's the results of this race."
                  gridType="results"
                />
              </>
            )}
          </Box>
        </Flex>
      }
      drawerFooter={
        <>
          <Flex gap={4} mb={4}>
            <RRaceStatus status={race.status as RaceStatus} />

            <RDateWithLabel
              time={race?.startTime as string}
              status={race?.status as RaceStatus}
            />
          </Flex>

          <ButtonGroup w="full">
            <Button
              isDisabled={!race?.track.has2D || isMobileDevice()}
              width="full"
              variant="secondary-outlined"
              cursor="pointer"
              onClick={(e) => {
                if (!race?.track.has2D) {
                  e.preventDefault();
                } else {
                  window.open(url('2D'), '_blank');
                }
              }}
            >
              Replay in 2D
            </Button>

            <Button
              isDisabled={!race?.track.has3D || isMobileDevice()}
              width="full"
              variant="secondary-outlined"
              cursor="pointer"
              onClick={(e) => {
                if (!race?.track.has3D) {
                  e.preventDefault();
                } else {
                  window.open(url('3D'), '_blank');
                }
              }}
            >
              Replay in 3D
            </Button>
          </ButtonGroup>
          {isMobileDevice() && (
            <Text fontSize="0.75rem" color="bloodMoon.100" mt="0.25rem">
              Mobile viewing coming soon
            </Text>
          )}
        </>
      }
    />
  );
};

export default RaceResultsDrawer;
