import { useState } from 'react';
import {
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  UseDisclosureProps,
  useDisclosure,
} from '@chakra-ui/react';

import { RDrawer, RPartCard } from 'components';
import { useGetTicketsAvailable, useRepairCarParts } from 'hooks';
import { IconTicket, IconWrenchScrewdriver } from 'icons';
import { CarPart } from 'types';
import RepairPartsModal from 'components/RepairPartsModal/RepairPartsModal';
import { useQueryClient } from '@tanstack/react-query';
import { useAnimatedTransition } from 'context';


interface RepairPartsDrawerProps extends UseDisclosureProps {
  carId?: string;
  carParts?: CarPart[];
  maximumTimesUsed: number | null;
}

const RepairPartsDrawer = ({
  carId = '',
  carParts,
  maximumTimesUsed,
  isOpen = false,
  onClose = () => null,
}: RepairPartsDrawerProps) => {
  const [selectedCarPartId, setSelectedCarPartId] = useState<string>();

  const { showAnimatedTransition } = useAnimatedTransition();

  const {
    onClose: modalClose,
    isOpen: modalOpen,
    onOpen: modalOnOpen,
  } = useDisclosure();

  const queryClient = useQueryClient();
  const { data } = useGetTicketsAvailable();
  const { mutateAsync, isLoading } = useRepairCarParts();

  const handleSelectCarPart = (partId: string) => {
    setSelectedCarPartId(partId);
  };

  const handleClose = () => {
    setSelectedCarPartId('');
    onClose();
  };

  const formatTicketText = (count: number) => {
    const ticketCount = count || 1;
    return `${ticketCount} ticket${ticketCount > 1 ? 's' : ''}`;
  };

  const handleRepairCarParts = async () => {
    await mutateAsync({
      carId,
    });

    queryClient.invalidateQueries({
      queryKey: ['garageQuery'],
    });
    queryClient.invalidateQueries({
      queryKey: ['ticketsAvailable'],
    });

    showAnimatedTransition({
      Icon: IconWrenchScrewdriver,
      id: 'car-repaired-success',
      title: 'Car',
      subtitle: 'Repaired',
      color: 'treasuredTeal.100',
      bgColor:
        'linear-gradient(116deg, #04360F -11.26%, #000400 30.41%, #011205 71.39%, #006845 104.43%)',
      gradientColor: {
        from: 'rgba(72, 223, 187, 0.2)',
        to: 'rgba(72, 223, 187, 0)',
      },
    });

    handleClose();
  };

  return (
    <>
      <RDrawer
        heading="Repair Parts"
        isOpen={isOpen}
        onClose={handleClose}
        drawerBody={
          <Flex w="full" flexDirection="column">
            <Flex
              m="2rem 0"
              alignItems="center"
              justifyContent="space-between"
              h="2.25rem"
              w="full"
            >
              <Text mb="2">
                Parts are required to be repaired at each interval, randomizing their attributes, and presenting players with a fresh set of challenges.
                Hit the track, and see what you're working with!
              </Text>
            </Flex>

            <Grid gridTemplateColumns="50% 50%" gap="2" mb="20">
              {carParts && carParts
                .map((part) => {
                  const isApplied = selectedCarPartId === part.id;
                  return (
                    <RPartCard
                      key={part.id}
                      flexBasis="49%"
                      h="9.25rem"
                      carPart={{ ...part, isApplied }}
                      onClick={() => handleSelectCarPart(part.id)}
                    />
                  );
                })}
            </Grid>
          </Flex>
        }
        drawerFooter={
          <Flex
            w="full"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              fontSize="1rem"
              textTransform="uppercase"
              mb="1.5rem"
              fontWeight="normal"
            >
              {data?.ticketsAvailable || 0} Tickets Available
            </Heading>
            <Button
              w="full"
              paddingY="1.5rem"
              variant="tertiary"
              isDisabled={
                data?.ticketsAvailable === 0 || !selectedCarPartId
              }
              isLoading={isLoading}
              onClick={modalOnOpen}
            >
              <IconTicket mr="0.4rem" w="2rem" h="2rem" />
              Repair for 1 ticket
            </Button>
          </Flex>
        }
      />
      <RepairPartsModal
        isOpen={modalOpen}
        onClose={modalClose}
        confirmFn={handleRepairCarParts}
        parts={selectedCarPartId}
      />
    </>
  );
};

export default RepairPartsDrawer;
