import { Box, Heading, Text } from '@chakra-ui/react';

import { CurrencyType } from 'api/generated/graphql';
import { RAlert, RTicketCard } from 'components';

interface AdmissionUseProps {
  admissionFee: number;
  currency?: CurrencyType;
}

const AdmissionUse = ({
  admissionFee,
  currency = CurrencyType.Usd,
}: AdmissionUseProps) => {
  return (
    <Box height="fit-content" pb="20">
      <Heading
        fontWeight="light"
        as="h1"
        size="lg"
        textTransform="uppercase"
        mb="3"
      >
        Admission Fee
      </Heading>

      <Box mb="8">
        <Text mb="4">The cost of admissision into the race is:</Text>
      </Box>

      <RAlert
        variant="info"
        description="Please note: If the race gets cancelled we will refund your ticket."
        mb="1rem"
      />

      <RTicketCard fee={admissionFee} currency={currency} />
    </Box>
  );
};

export default AdmissionUse;
